import axios from "axios";
import axiosRetry from "axios-retry";

import {
  fixDistributorReportQuery,
  fixPostImplantData,
  fixPostSurgeryData,
  fixPostUser,
  fixPostWarrantyExtendData,
  hidratePatientSearchResponse,
  hidrateUsers,
  hidrateUsersActivityLog,
} from "./hidraters";

// import users from "./users.json";
//

axiosRetry(axios, {
  retries: 3,
  retryDelay: (count) => count * 1000,
});

export const roles = [
  "ROLE_NONE",
  "ROLE_ADMIN",
  "ROLE_VIEW",
  "ROLE_COMPLIANCE",
  "ROLE_SUPPORT",
  "ROLE_PMS",
  "ROLE_US_APPROVAL"];

export const ROLES = {
  SUPPORT: "ROLE_SUPPORT",
  ADMIN: "ROLE_ADMIN",
  VIEW: "ROLE_VIEW",
  COMPLIANCE: "ROLE_COMPLIANCE",
  PMS: "ROLE_PMS",
  US_APPROVAL: "ROLE_US_APPROVAL"
};

export default class API {
  constructor(options = {}) {
    this.globalOptions = options;
  }
  async api(method, url, payload, options = {}, contentType) {
    const calculatedOptions = this.fixOptions(options);
    const req = this.buildRequest(method, url, payload, calculatedOptions, contentType);

    return this.fetch(req);
  }

  fixOptions(options) {
    return { ...this.globalOptions, ...options };
  }

  async fetch(options) {
    const res = await axios(options);

    return res.data;
  }

  buildUrl(url, query, { baseUrl } = {}) {
    const uri = new URL(url, baseUrl || process.env.REACT_APP_API_BASE_URL);

    uri.search = new URLSearchParams(query);

    return uri.toString();
  }

  buildRequest(
    method,
    url,
    payload,
    { headers, queryString, ...options } = {},
    contentType
  ) {
    return {
      url: this.buildUrl(url, queryString, options),
      method: method.toUpperCase(),
      data: contentType === "json" ? payload : this.formDataPayload(payload),
      headers,
    };
  }

  get(url, queryString, options) {
    return this.api("get", url, null, { ...options, queryString });
  }

  post(url, values, options, contentType) {
    return this.api("post", url, values, options, contentType);
  }

  put(url, values, options, contentType) {
    return this.api("put", url, values, options, contentType);
  }

  delete(url, queryString, options) {
    return this.api("delete", url, null, { ...options, queryString });
  }

  formDataPayload(payload) {
    if (payload && payload instanceof Object) {
      const formData = new FormData();

      Object.entries(payload).forEach(([key, value]) =>
        formData.append(key, value)
      );

      return formData;
    }

    return null;
  }

  async patientSearch(query) {
    const apiQuery = (query) => {
      const result = {};

      if ("email" in query) {
        result["email"] = query.email;
      } else if ("serial" in query) {
        result["serialNumber"] = query.serial;
      } else if ("eserial" in query) {
        result["serialNumber"] = query.eserial;
        result["is_Q"] = true;
      }

      return result;
    };

    return this.get("user/search", apiQuery(query))
      .then(hidratePatientSearchResponse)
      .catch(() => ({ resultsCount: 0, results: [] }));
  }

  getPatientErrors(id) {
    return this.get(`user/${id}/errors`);
  }

  getImplantDetail(id) {
    return this.get(`implant/${id}`);
  }

  getImplantTransactions(id) {
    return this.get(`implant/${id}/transactions`);
  }

  async getUsers() {
    return this.get("auth/users").then(hidrateUsers);
  }

  async getUsersActivityLog() {
    return this.get("logs").then(hidrateUsersActivityLog);
  }

  async getUser(email) {
    const users = await this.getUsers();
    const user = users.find((user) => user.email === email);

    return Promise.resolve({ user });
  }

  // Send role as roleId
  async postUser(user) {
    return this.post("auth/user", fixPostUser(user));
  }

  async updateUser(id, user) {
    return this.post(`auth/user/${id}`, fixPostUser(user));
  }

  async deleteUser(email, user) {
    return this.post(`auth/delete/user/`, { email });
  }

  getCountries() {
    return this.get("countries", null, {
      baseUrl: process.env.REACT_APP_MOTIVA_API_BASE_URL,
    });
  }

  getVersion() {
    return this.get("/version/WC", "", {
      baseUrl: process.env.REACT_APP_MOTIVA_API_BASE_URL.substring(0,process.env.REACT_APP_MOTIVA_API_BASE_URL.length-4)
    });
  }

  getFeatures() {
    return this.get("features", "", {
      baseUrl: process.env.REACT_APP_MOTIVA_API_BASE_URL,
    });
  }

  getIncisionKinds() {
    return this.get("implants/breasts/incision-kinds", null, {
      baseUrl: process.env.REACT_APP_MOTIVA_API_BASE_URL,
    });
  }

  getSurgeryKinds() {
    return this.get("implants/breasts/surgery-indication", null, {
      baseUrl: process.env.REACT_APP_MOTIVA_API_BASE_URL,
    });
  }

  getBreastPlacements() {
    return this.get("implants/breasts/placement", null, {
      baseUrl: process.env.REACT_APP_MOTIVA_API_BASE_URL,
    });
  }

  postValidateDevice(payload) {
    return this.post(`validate/device`, payload,  null,"json").then((data) => data.result);
  }

  postCompleteTemporaryExplant(payload) {
    return this.post(`completeTemporaryExplant`, payload,  null,"json").then((data) => data);
  }

  postPatient(id, values) {
    return this.post(`user/${id}`, values);
  }

  postPatientSendEmail(id) {
    return this.post(`user/${id}/send_email`);
  }

  postPatientGeneratePassword(id, values) {
    return this.post(`user/${id}/generate_password`, values);
  }

  postImplant(id, values) {
    return this.post(`implant/${id}`, fixPostImplantData(values));
  }

  postSurgery(id, values) {
    return this.post(`surgery/${id}`, fixPostSurgeryData(values));
  }

  postWarrantyExtend(id, values) {
    return this.post(
      `implant/${id}/warranty`,
      fixPostWarrantyExtendData(values)
    );
  }

  async surgeonSearch(query) {
    try {
      return this.get("doctors/search", query);
    } catch (e) {
      return [];
    }
  }

  async postUserType(payload) {
    return this.post(`support/changeUserTypeByMail`, payload).then((data) => data);
  }

  async getDistributors(country) {
    return this.get(`distributors/${country}`).then((data) => data.results);
  }

  async getDistributorReport(query) {
    return this.post(
      "search/implantsByDistr",
      fixDistributorReportQuery(query),
      {
        responseType: "blob",
      }
    );
  }

  async getCustomerCareReport(query) {
    return this.post("search/implants", query, { responseType: "blob" });
  }

  async getImplantsReport(payload) {
    let params ={
      ...payload,
      type: payload.type === "0" ? "B" : "G",
      lot: payload.lot ? payload.lot : "",
      is_registered: payload.is_registered ? payload.is_registered : "",
      not_blocked: payload.not_blocked ? payload.not_blocked : "",
      is_tracked: payload.is_tracked ? payload.is_tracked : "",
      is_q_inside: payload.is_q_inside ? payload.is_q_inside : "",
      distributor: payload.distributor ? payload.distributor : "",
      family_type: payload.family_type ? payload.family_type : "",
      status: payload.status ? payload.status : ""
    }

    const query = '?' + new URLSearchParams(params).toString();
    return await this.get(`implant/getImplantsListForReports`, query).then((data) => data.message);
  }

  async getDeviceTrackingReport(payload) {
    return this.post(`reportPMS?page=${payload.page}`, payload).then((data) => data.message);
  }

  async getDownloadPMS(payload) {
    return this.post(`download/reportPMS`, payload).then((data) => data);
  }

  async getBIAnnualReport(payload) {
    return this.post(`reportBiAnual/deviceEvent`, payload).then((data) => data);
  }

  async getDeviceTrackingTempReport() {
    return this.get(`reportTemporaryByLot`, null).then((data) => data.message);
  }

  async getCatalogByGroup(payload) {
    return this.get(`catalog/groupList`, payload).then((data) => data);
  }

  async updateCatalog(payload) {
    return this.post(`catalog/update`, payload);
  }

  async postSendEmails(payload) {
    return this.post(`support/sendEmails`, payload,'','json').then((data) => data);
  }

  async getAllDistributors(page) {
    return this.get("distributor/read", `page=${page}`);
  }
  async updateDistributor(payload) {
    return this.post(`distributor/update`, payload);
  }
  async createDistributor(payload) {
    return this.post(`distributor/create`, payload);
  }
  async deleteDistributor(payload) {
    return this.post(`distributor/delete`, payload);
  }

  async getAllUsersApplication(page, queryParam) {
    //return this.get("users-application-state", `page=${page}`);
    return this.get(`users-application-state/search/filter`, `page=${page}${queryParam}`);
  }

  /*async getAllUsersApplicationFilter(page) {
    return this.get(`users-application-state/search/filter`, `page=${page}${queryParam}`);
  }*/

  async getAllInfluencers(page, queryParam) {
    return this.get("admin/influencers/search", `page=${page}${queryParam}`,
        { baseUrl: process.env.REACT_APP_WARRANTIES_API_BASE_URL,}
        ,'json');
  }
  async updateInfluencer(payload) {
    return this.put("admin/influencers", payload, {
      baseUrl: process.env.REACT_APP_WARRANTIES_API_BASE_URL,
    },'json');
  }
  async createInfluencer(payload) {
    return this.post("admin/influencers", payload, {
      baseUrl: process.env.REACT_APP_WARRANTIES_API_BASE_URL,
    },'json');
  }
  async deleteInfluencer(id) {
    return this.delete("admin/influencers", `id=${id}`,
        { baseUrl: process.env.REACT_APP_WARRANTIES_API_BASE_URL,}
        ,'json');
  }


  async createUsersApplication(payload) {
    return this.post(`users-application-state`, payload);
  }

  postAddImplant(payload) {
    return this.post("saveImplant", payload, {
      baseUrl: process.env.REACT_APP_WARRANTIES_API_BASE_URL,
    },'json');
  }
}
